import React from 'react';
import './Footer.css'; // Make sure the CSS path is correct

function Footer() {
    return (
        <footer className="footer">
            <p>© 2024 ReNest. All rights reserved.</p>
        </footer>
    );
}

export default Footer;
