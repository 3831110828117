import React from 'react';

function InvestmentOpportunities() {
  return (
    <div style={{ padding: '20px', textAlign: 'center', fontFamily: 'Quantico' }}>
      <h1>Investment Opportunities</h1>
      <p>Explore exclusive real estate investment opportunities here.</p>
      <p>Our platform offers a variety of properties to meet the needs of both new and experienced investors.</p>
    </div>
  );
}

export default InvestmentOpportunities;
